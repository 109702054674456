<template>
  <ui-component-modal
    :modalTitle="'Create to-do'"
    modalSize="large"
    :isSaving="isSaving"
    :onClickCancel="onClickCancel"
    :onClickSave="createToDo"
    :showModal="showModal"
    :isSavingSuccess="isSavingSuccess"
    :disableSaveButton="
      newToDo.Subject === '' || newToDo.AccountIds.length === 0
    "
    :isSavingError="isSavingError"
    :savingSuccessMessage="
      $t('Components.ToDos.ModalCreateToDo.Message_Success')
    "
    :savingErrorMessage="$t('Components.ToDos.ModalCreateToDo.Message_Error')"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :buttonLabelConfirm="$t('Components.ToDos.ModalCreateToDo.Button_Confirm')"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <ui-base-input-field
        class="has-margin-bottom-x2"
        :label="'Subject'"
        type="text"
        v-model="newToDo.Subject"
        :placeholder="'Subject'"
      />
      <textarea
        rows="5"
        label="Additional info"
        type="text"
        ref="Body"
        v-model="newToDo.Body"
        class="textarea has-margin-bottom-x2"
        :placeholder="'Body'"
      />
      <BaseFieldTemplate class="has-margin-bottom-x2" :label="'Do before'">
        <template v-slot:fields>
          <UiBaseDateField
            v-model="doBeforeDate"
            :isRange="false"
            :is-inline="true"
            :openCalendarInModal="true"
            @updateDates="
              (val) => {
                newToDo.DoBefore = val.getTime()
              }
            "
          />
        </template>
      </BaseFieldTemplate>
      <div class="form-options columns">
        <div class="search-add column column-search">
          <ui-loader v-if="isLoading === true" :height="21" />
          <ui-base-checkbox
            v-else
            v-model="assignToSelf"
            :label="'Assign to self'"
            @input="checkIfAssigned"
            :cssClassLabel="'has-text-weight-bold'"
          />
          <hr />

          <div class="tabs-info">
            <ui-loader v-if="isLoading === true" />
            <table v-if="!isLoading" class="table is-fullwidth is-striped">
              <tbody>
                <tr
                  v-for="(account, index) in displayAccounts"
                  :key="`op${index}`"
                  overflow-y:scroll
                >
                  <td v-html="account.FullName"></td>
                  <td>
                    <a
                      @click="addAccount(account)"
                      class="button is-small is-success"
                    >
                      <span class="icon is-small">
                        <font-awesome-icon :icon="['fas', 'plus']" />
                      </span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="menu-options column">
          <div class="selected-options-wrapper">
            <div class="title is-3">Selected managers</div>

            <div v-if="!selectedAccounts.length">
              <message :message="'No managers selected'" />
            </div>

            <div v-else class="wrapper-selectedOptions">
              <table class="table is-fullwidth is-striped">
                <tbody>
                  <tr
                    v-for="(account, index) in selectedAccounts"
                    :key="`sl${index}`"
                  >
                    <td>{{ account.FullName }}</td>
                    <td>
                      <a @click="removeAccount(account)">
                        <span class="icon">
                          <font-awesome-icon
                            :icon="['fas', 'trash-alt']"
                            class="has-text-danger"
                          />
                        </span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations, mapGetters } from 'vuex'
import accountProvider from '@/providers/account'
import BaseFieldTemplate from '@/components/UI/Form/BaseFieldTemplate'
import todoProvider from '@/providers/todo'
const Message = () => import('@/components/UI/Message')
const UiBaseDateField = () => import('@/components/UI/Form/BaseDateField')

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },

    itemId: {
      default: 0,
      type: Number,
    },

    locationId: {
      default: 0,
      type: Number,
    },

    typeId: {
      default: 'None',
      type: String,
    },

    viewType: {
      default: 'normal',
      type: String,
    },

    workEnvironment: {
      type: String,
      default: 'Frontend',
    },

    environmentId: {
      type: Number,
      default: 0,
    },
  },

  components: {
    Message,
    //SearchBar,
    BaseFieldTemplate,
    UiBaseDateField,
  },

  data() {
    return {
      channelId: this.$route.params.channelId
        ? Number(this.$route.params.channelId)
        : 0,
      isSavingError: false,
      isSavingSuccess: false,
      isSaving: false,
      isLoading: false,
      newToDo: {},
      doBeforeDate: new Date(),
      accountIds: [],
      selectedAccountIds: [],
      selectedAccounts: [],
      accounts: [],
      searchTerm: '',
      assignToSelf: false,
    }
  },

  computed: {
    ...mapState('accountStore', ['accountGroups']),
    ...mapState('locationStore', ['locationState']),
    ...mapGetters('locationStore', ['accountRights']),
    ...mapGetters({
      profile: 'profileStore/getProfile',
    }),

    displayAccounts() {
      let result = []
      if (this.accounts.length > 0) {
        for (let i = 0; i < this.accounts.length; i++) {
          let accountIndex = this.selectedAccounts.findIndex(
            (a) => a.Id === this.accounts[i].Id
          )
          if (accountIndex === -1) {
            result.push(this.accounts[i])
          }
        }
      }
      return result
    },
  },

  async created() {
    this.newToDo = this.setNewToDo()
    this.getChannelAccounts()
  },

  mounted() {},

  methods: {
    ...mapMutations('accountStore', ['setAccountGroups']),

    checkIfAssigned() {
      let addIndex
      let removeIndex
      let removeIndex2

      if (this.assignToSelf && this.selectedAccounts.length > 0) {
        addIndex = this.newToDo.AccountIds.findIndex(
          (id) => id === this.profile.Id
        )
      }

      if (
        (this.assignToSelf && addIndex === -1) ||
        (this.selectedAccounts.length === 0 && this.assignToSelf)
      ) {
        this.newToDo.AccountIds.push(this.profile.Id)

        for (let i = 0; i < this.accounts.length; i++) {
          if (this.accounts[i].ProfileId === this.profile.Id) {
            this.selectedAccounts.push(this.accounts[i])
          }
        }
      }

      if (!this.assignToSelf && this.newToDo.AccountIds.length > 0) {
        removeIndex = this.newToDo.AccountIds.findIndex(
          (id) => id === this.profile.Id
        )
      }

      if (removeIndex > -1) {
        Vue.delete(this.newToDo.AccountIds, removeIndex)
      }
      if (!this.assignToSelf && this.selectedAccounts.length > 0) {
        removeIndex2 = this.selectedAccounts.findIndex(
          (a) => a.ProfileId === this.profile.Id
        )

        if (removeIndex2 > -1) {
          Vue.delete(this.selectedAccounts, removeIndex2)
        }
      }
    },

    changeTab(tab) {
      this.infoTab = tab
    },

    async getChannelAccounts() {
      if (this.accounts.length === 0) {
        this.isLoading = true
        let searchTerm = ''
        let page = 0

        await accountProvider.methods
          .getAllChannelAccounts(this.channelId)
          .then((response) => {
            if (response.status === 200) {
              this.accounts = response.data
              this.mAssignToSelf = this.checkIfAssigned()

              for (let i = 0; i < this.accounts.length; i++) {
                this.accounts[i].FullName = this.accounts[i].ProfileName
              }

              this.accountIds = this.accountIds.concat(
                this.newToDo.AccountIds.filter(
                  (a) => a.ProfileId !== this.profile.Id
                ).map((a) => a.ProfileId)
              )
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },

    addAccount(account) {
      if (account.ProfileId === this.profile.Id) {
        this.assignToSelf = true
      }
      this.newToDo.AccountIds.push(account.ProfileId)
      this.selectedAccounts.push(account)
    },

    removeAccount(account) {
      // Remove selected account from newTodo accountids array
      let index = this.newToDo.AccountIds.findIndex(
        (id) => id === account.ProfileId
      )
      if (index > -1) {
        Vue.delete(this.newToDo.AccountIds, index)
      }

      // Remove option from selected accounts list
      let index2 = this.selectedAccounts.findIndex(
        (a) => a.ProfileId === account.ProfileId
      )
      if (index2 > -1) {
        Vue.delete(this.selectedAccounts, index)
      }

      if (account.ProfileId === this.profile.Id) {
        this.assignToSelf = false
      }
    },

    search(searchTerm) {
      let self = this
      this.isLoading = true
      this.searchTerm = searchTerm
      this.accounts = []
      this.getChannelAccounts()
    },

    createToDo() {
      let self = this

      self.isDoneSaving = false
      if (self.newToDo.Subject.trim() !== '' && !self.isSaving) {
        self.isSaving = true

        todoProvider.methods
          .createToDo(self.newToDo)
          .then((response) => {
            if (response.status === 201) {
              self.$emit('todoCreated', response.data)

              self.newToDo = self.setNewToDo()

              self.onClickCancel()
            }
          })
          .catch((error) => {
            self.isSavingError = true
            //console.log(error)
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },

    setNewToDo() {
      let todo = {
        WorkEnvironment: this.workEnvironment,
        EnvironmentId: this.environmentId,
        TypeId: this.typeId,
        ItemId: this.itemId,
        LinkTypeId: this.typeId,
        LinkItemId: this.itemId,
        DeliveryAt: new Date().getTime(),
        Subject: '',
        Body: '',
        DoBefore: new Date().getTime(),
        AccountIds: [],
        Id: 0,
        IsToDo: true,
      }
      return todo
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/template';
.tabs {
  margin-bottom: 2px;
}
.tabs-info {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  border-radius: 5px;
  border-top: none;
  height: 240px;
}

.table {
  td {
    &:first-child {
      width: 100%;
    }
  }
}
</style>
